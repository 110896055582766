<template>
  <div
    class="head-row"
    :class="{ head: true }"
  >
    <div class="head-row__top-wrapper">
      <div 
        class="head-row_top" 
        :class="{ row_mb: showCouponCode }"
      >
        <template
          v-for="(rule, index) in item.coupon_rule"
          :key="`coupon-rule-${index}`"
        >
          <div
            v-if="index < 3"
            class="sub-item"
            :class="{
              'has-line': index < item.coupon_rule.length - 1 && index < 2,
              'sub-item-2': item.coupon_rule.length == 2,
              'sub-item-3': item.coupon_rule.length > 2,
              'sub-status-1': isStartOn(item)
            }"
          >
            <coupon-sub-title
              v-model:title-length="titleLength"
              :item="item"
              :ssr="ssr"
              :coupon-rule="rule"
              :rule-len="item.coupon_rule.length"
              :language="language"
              :index="index"
              :direction-type="directionType"
              v-bind="$attrs"
              :coupon-type="couponType"
              :is-new-style="isNewStyle"
            />

            <div
              v-if="page === 'userCoupon'"
              class="head-right"
            >
              <slot name="handle"></slot>
            </div>

            <div
              v-if="index < item.coupon_rule.length - 1 && index < 2"
              class="sub-line"
            ></div>
          </div>

          <!-- 在用户回归弹窗的时候，在有档位，即coupon_rule的长度大于1时候不显示time-tips -->
          <time-tips
            v-if="item.coupon_rule.length == 1 && couponType == 'revisit-coupon' &&!forRevisitComponent"
            :item="item"
            :page="page"
            v-bind="$attrs"
            :language="language"
            :coupon-type="couponType"
          />
        </template>
      </div>
      
      <s-button
        v-if="isShowPaySuccessBtn"
        :type="['primary', 'H28PX']"
        class="head-row__top-wrapper-btn"
        @click="viewCouponList"
      >
        {{ language.SHEIN_KEY_PC_17703 }} >
      </s-button>
    </div>

    <!-- 优惠券信息 -->
    <slot name="main"></slot>

    <div class="head-row__labels">
      <!-- 下单页可叠加标识（多档券） -->
      <span v-if="item.coupon_rule.length > 1 && stageable">
        {{ language.SHEIN_KEY_PC_22135 }}
      </span>
    </div>

    <coupon-categories
      v-if="isNewStyle && item.category_name.length"
      class="head-row__categories"
      :item="item"
    />
  </div>
</template>

<script>
import couponSubTitle from './couponSubTitle.vue'
import timeTips from '../timeTips.vue'
import couponCategories from './couponCategories.vue'

export default {
  name: 'HeadRow',
  components: { couponSubTitle, timeTips, couponCategories },
  props: {
    ssr: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: null,
    },
    language: {
      type: Object,
      default: null,
    },
    isHeadOld: {
      type: Boolean,
      default: false,
    },
    showCouponCode: {
      // 是否展示优惠券券码
      type: Boolean,
      default: true,
    },
    directionType: {
      // 多档券排列顺序
      type: String,
      default: 'column',
    },
    isShowPaySuccessBtn: {
      // 是否展示支付成功按钮
      type: Boolean,
      default: false
    },
    couponType: {
      // 弹窗类型，用户回归：revisit-coupon
      type: String,
      default: ''
    },
    forRevisitComponent: {
      type: Boolean,
      default: false
    },
    page: {
      // 哪个页面的券列表
      type: String,
      default: '',
    },
    stageable: {
      // 是否可叠加
      type: Boolean,
      default: false,
    },
    isNewStyle: {
      // 是否新样式
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      titleLength: 0,
    }
  },
  watch: {
    titleLength(val) {
      if (val > 1) {
        this.$emit('update:isHeadOld', true)
      }
    },
  },
  methods: {
    viewCouponList () {
      this.$emit('view-coupon-list')
    },
    isStartOn(item) {
      return this.page === 'userCoupon' && item.timeStatus === '1'
    }
  },
  emits: ['update:isHeadOld', 'view-coupon-list'],
}
</script>

<style lang="less">
.head-row {
  width: 100%;
  flex-direction: column;
  align-items: flex-start;

  &__top-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  &__top-wrapper-btn {
    .margin-l(12px);
  }
  &_top {
    width: 100%;
    display: flex;
    align-items: flex-start;
    .title {
      font-size: 12px;
      // white-space: nowrap;
      word-break: break-all;
      text-align: left;
    }
  }
  .row_mb {
    margin-bottom: 12px;
  }
  .sub-item {
    display: flex;
    align-items: center;
    width: 100%;
  }
  .sub-item-2 {
    width: 50%;
    &.has-line {
      width: calc(50% + 17px);
    }
  }
  .sub-item-3 {
    width: 33.33%;
    &.has-line {
      width: calc(33.33% + 19px);
    }
  }

  .sub-status-1 {
    justify-content: space-between;

    .title-section {
      width: unset;
    }
  }

  .sub-line {
    width: 1px;
    height: 32px;
    border-right: 1px dashed @sui_color_promo;
    margin: 0 8px;
  }

  &__labels {
    align-self: flex-start;
    > span {
      font-family: SF UI Text;
      font-size: 10px;
      padding: 0 4px;
      border-radius: 1px;
      border: 1px solid fade(@sui_color_welfare, 20%);
      line-height: 16px;
      font-weight: 400;
      white-space: nowrap;
    }
  }

  &__categories {
    align-self: flex-start;
  }
}
</style>
