<template>
  <div :class="{ head: true}">
    <div
      v-if="item.real_type_id == '53' && !showWraperImg"
      class="shein-club"
    >
      <img
        :src="`${PUBLIC_CDN}/she_dist/images/coupon/shein-club-logo-bfddb94200.png`"
        style="width: 100%;height: 100%;"
      />
    </div>
    <div
      class="header-wrapper bg-wrapper"
      :style="{
        backgroundImage:
          item.times > 100000 && !(isExpired || item.coupon_status == '3')
            ? `url(${IMG_LINK[GB_cssRight ? 'prime_coupon_hd_bg_ar' : 'prime_coupon_hd_bg']})`
            : 'none',
      }"
    >
      <div class="head-left">
        <template v-for="(rule, index) in item.coupon_rule">
          <div 
            v-if="index < 1" 
            :key="`rule-${index}`"
            class="sub-item" 
          >
            <coupon-sub-title
              :is-new-user="isNewUser"
              :item="item"
              :coupon-rule="rule"
              :language="language"
              :direction-type="directionType"
              v-bind="$attrs"
              :coupon-type="couponType"
              :is-expired="isExpired"
              :is-new-style="isNewStyle"
              :show-wraper-img="showWraperImg"
            />
          </div>
        </template>

        <!-- 优惠券信息 -->
        <slot name="main"></slot>
        <coupon-categories
          v-if="isNewStyle && item.category_name.length" 
          :item="item"
        />
      </div>

      <div class="head-right">
        <slot name="handle"></slot>

        <time-tips 
          v-if="showTimeTips" 
          :item="item" 
          v-bind="$attrs"
          :page="page"
          :language="language" 
          :coupon-type="couponType" 
        />
      </div>
    </div>
  </div>
</template>

<script>
import timeTips from '../timeTips.vue'
import couponSubTitle from './couponSubTitle.vue'
import couponCategories from './couponCategories.vue'

const { IMG_LINK, GB_cssRight, PUBLIC_CDN } = gbCommonInfo
export default {
  name: 'HeadOld',
  components: { timeTips, couponSubTitle, couponCategories },
  props: {
    isNewUser: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default: null,
    },
    language: {
      type: Object,
      default: null,
    },
    isHeadOld: {
      type: Boolean,
      default: false,
    },
    directionType: {
      // 多档券排列顺序
      type: String,
      default: 'column',
    },
    isExpired: {
      // 是否失效
      type: Boolean,
      default: false,
    },
    showCoupondown: {
      type: Boolean,
      default: false
    },
    isShowCountdownFlag: {
      // 是否展示倒计时标识
      type: Boolean,
      default: false,
    },
    couponType: {
      // 弹窗类型，用户回归：revisit-coupon
      type: String,
      default: ''
    },
    forRevisitComponent: {
      type: Boolean,
      default: false,
    },
    page: {
      // 哪个页面的券列表
      type: String,
      default: '',
    },
    isNewStyle: {
      // 是否新样式
      type: Boolean,
      default: false,
    },
    showWraperImg: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      IMG_LINK,
      GB_cssRight,
      PUBLIC_CDN
    }
  },
  computed: {
    showTimeTips() {
      // 店铺页未开始券
      if (this.item.timeStatus === '1' && this.page === 'userCoupon') {
        return !this.isShowCountdownFlag
      }
      // 倒计时优先级更高
      return (!this.showCoupondown || !this.forRevisitComponent) && !this.isShowCountdownFlag
    }
  }
}
</script>
