<template>
  <div
    :id="`coupon-item-${item.keyId}`"
    v-expose="exposeAnalyse(item)"
    class="coupon-item-wrapper"
    :class="{
      expired: isExpired || item.coupon_status == '3',
      'prime-coupon': item.real_type_id == '53' && !isGreenThemeConfig,
      'prime-coupon__max': item.times > 100000,
      'prime-coupon_white': item.real_type_id == '53' && isWhiteBackground && !isGreenThemeConfig,
      'prime-coupon__logo': item.real_type_id == '53' && !showWraperImg,
      'is-new-user': isNewUser,
      'is-saver-coupon': item.real_type_id == '55' && !isGreenThemeConfig,
      'is-expand-coupon': isExpandCoupon,
      'coupon-for-prime': item.real_type_id == '58' && !isGreenThemeConfig,
      'coupon-for-saver': item.real_type_id == '59' && !isGreenThemeConfig,
      'hide-coupon-header': hideCouponHeader
    }"
    :tabindex="unusedCouponHas ? '0' : '-1'"
    :aria-hidden="!unusedCouponHas"
    :style="{ 
      border:couponType=='revisit-coupon' && 'none',
      ...couponBackgroundStyle,
      ...themeConfig,
    }"
  >
    <img 
      v-if="couponBackgroundLogo"
      class="coupon-item__logo"
      :src="couponBackgroundLogo"
    />
    
    <!-- 券头部信息 -->
    <component
      :is="headType"
      v-bind="$attrs"
      :id="`coupon-head-${item.coupon.toLowerCase()}`"
      v-model:is-head-old="isHeadOld"
      :is-new-user="isNewUser"
      :item="item"
      :language="language"
      :is-satisfied-range="isSatisfiedRange"
      :is-show-free-gift="isShowFreeGift"
      :show-coupon-code="showCouponCode"
      :direction-type="directionType"
      :is-expired="isExpired"
      :show-coupondown="showCoupondown"
      :is-show-countdown-flag="isShowCountdownFlag"
      :coupon-type="couponType"
      :for-revisit-component="forRevisitComponent" 
      :is-new-style="isNewStyle"
      :page="page"
      :is-green-theme="isGreenThemeConfig"
      :show-wraper-img="showWraperImg"
    >
      <!-- 新用户样式券包这些都不展示 -->
      <!-- 回归用户弹窗不需要券主体信息 -->
      <template #main>
        <!-- 券主体信息 ,直接作为回归弹窗组件或者作为A样式的时候不展示main-->
        <coupon-main
          v-if="couponType != 'revisit-coupon' && !forRevisitComponent && !isNewUser"
          :expanded="expanded"
          v-bind="$attrs"
          :item="item"
          :language="language"
          :show-coupon-code="showCouponCode"
          :is-new-style="isNewStyle"
        />
      </template>
      <template #handle>
        <!-- 优惠券操作或者过期提示 -->
        <coupon-handle
          v-bind="$attrs"
          :item="item"
          :language="language"
          :is-expired="isExpired"
          :expired-type="expiredType"
          :unused-coupon-has="unusedCouponHas"
          :is-checkout="isCheckout"
          :is-mult-mall="isMultMall"
          :is-show-countdown-flag="isShowCountdownFlag"
          :is-show-pay-success-btn="isShowPaySuccessBtn"
          :direction-type="directionType"
          :isloaded="isloaded"
          :expand-abt-info="expandAbtInfo"
          :page="page"
        />
      </template>
    </component>

    <template v-if="hasDateRange">
      <div
        v-if="isNewUser"
        :class="{'is-new-user-mid-line': isNewUser}"
        :style="{
          backgroundImage: `url(${PUBLIC_CDN}/she_dist/images/coupon_box/coupon_center-2f9ba124b9.png)`
        }"
      ></div>
      
      <!-- 优惠券中间分隔线 -->
      <div
        v-else
        class="coupon-mid-line"
      >
        <div></div>
      </div>

      <!-- 券内容文案 -->
      <div
        class="body"
        :class="{ expand: isExpand || setExpand }"
      >
        <div
          class="tips-wrapper bg-wrapper"
          :style="{
            backgroundImage:
              item.times > 100000 && !(isExpired || item.coupon_status == '3')
                ? `url(${IMG_LINK[GB_cssRight ? 'prime_coupon_tips_bg_ar' : 'prime_coupon_tips_bg']})`
                : 'none',
          }"
        >
          <div
            :id="`coupon-desc-content-${item.keyId}`"
            class="coupon-desc-content"
            :style="{maxHeight:getAOrB && 'none',width:getAOrB&&'100%'}"
          >
            <coupon-tips
              :is-new-user="isNewUser"
              v-bind="$attrs"
              :item="item"
              :language="language"
              :is-mult-mall="isMultMall"
              :head-type="headType"
              :is-show-countdown-flag="isShowCountdownFlag"
              :show-coupon-code="showCouponCode"
              :direction-type="directionType"
              :coupon-type="couponType" 
              :for-revisit-component="forRevisitComponent"
              :show-new-transportation-way="showNewTransportationWay"
              :is-new-style="isNewStyle"
              :page="page"
              :show-date-range="showDateRange"
            />

            <div
              v-if="isShowPaySuccessBtn"
              class="check-btn"
              @click="viewCouponList"
            >
              {{ language.SHEIN_KEY_PC_17703 }} > 
            </div>
          </div>

          <!-- 收起/展开icon -->
          <div
            v-if="isTipIconShow"
            class="icon-tip"
            :class="{ 'mult-tips': isTipIconShowClass }"
            @click.stop="handleClick"
          >
            <Icon
              class="icon-tip__icon"
              name="sui_icon_circle_down_12px"
              color="#999999"
              size="12px"
            />
          </div>
        </div>
      </div>
    </template>

    <!-- 券使用次数 -->
    <div
      v-if="item.times > 1 && item.times < 100000 && !getAOrB"
      class="count"
    >
      <span v-if="item.is_expand == 1"> {{ language.SHEIN_KEY_PC_25025 }} </span> x{{ item.times }}
    </div>

    <!-- 券使用次数为1次 -->
    <div
      v-if="(item.times == 1 && item.is_expand == 1 && !getAOrB)"
      class="count"
    >
      <span> {{ language.SHEIN_KEY_PC_25025 }} </span> 
    </div>

    <!-- 无限免邮券提示 -->
    <div
      v-if="item.times > 100000 && !getAOrB"
      class="count max-count"
    >
      {{ language.SHEIN_KEY_PC_23601 }}
    </div>

    <!-- 最近12h获取的券提示--Newly Get角标 -->
    <div
      v-if="isShowNewlyGetFlag && !getAOrB"
      class="coupon__newly-get"
    >
      New
    </div>

    <template v-if="hdHeight && !isNewUser">
      <!-- 半圆分割线 -->
      <div
        class="coupon-cycle-border coupon-cycle-border__left"
        :style="{ top: `calc(${hdHeight} - .5px)` }"
      ></div>
      <div
        class="coupon-cycle-border coupon-cycle-border__right"
        :style="{ top: `calc(${hdHeight} - .5px)` }"
      ></div>
    </template>
  </div>
</template>

<script>
import couponHandle from './couponHandle.vue'
import couponTips from './couponTips.vue'
import couponMain from './couponMain.vue'
import headOld from './header/old.vue'
import headColumn from './header/column.vue'
import headRow from './header/row.vue'
import { daEventCenter } from 'public/src/services/eventCenter'
import { htmlDecode, template, debounce } from '@shein/common-function'
import { getTextLline } from './utils'
daEventCenter.addSubscriber({ modulecode: '1-22-1' })
const { IMG_LINK, GB_cssRight, PUBLIC_CDN } = gbCommonInfo
import { getSiteMallInfo } from 'public/src/pages/common/getSiteMallInfo'
import { PageSourceEnum } from './constants.js'
import { Icon } from '@shein-aidc/icon-vue3'

export default {
  name: 'CouponItem',
  components: {
    couponHandle,
    couponTips,
    couponMain,
    headOld,
    headColumn,
    headRow,
    Icon,
  },
  props: {
    isNewUser: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default: null,
    },
    language: {
      type: Object,
      default: null,
    },
    // 券曝光事件id
    exposeId: {
      type: String,
      default: ''
    },
    // 券曝光abt数据
    abtest: {
      type: String,
      default: ''
    },
    isShowPaySuccessBtn: {
      type: Boolean,
      default: false
    }, //按钮显示隐藏
    showCouponCode: {
      // 是否展示优惠券券码
      type: Boolean,
      default: true,
    },
    isExpired: {
      type: Boolean,
      default: false,
    },
    expiredType: {
      type: String,
      default: 'expired',
    },
    expanded: {
      type: [String, Number],
      default: 'false',
    },
    unusedCouponShow: {
      type: Boolean,
      default: false,
    },
    unusedCouponType: {
      type: Number,
      default: 0,
    },
    directionType: {
      // 多档券排列顺序
      type: String,
      default: 'column',
    },
    isSatisfiedRange: {
      // 是否满足档位展示拼接文案free gift
      type: Boolean,
      default: false,
    },
    isShowFreeGift: {
      // 是否展示赠品提示free gift
      type: Boolean,
      default: true,
    },
    isWhiteBackground: {
      type: Boolean,
      default: true
    },
    isShowNewly: {
      // 是否展示New角标
      type: Boolean,
      default: false
    },
    showCoupondown: {
      type: Boolean,
      default: false
    },
    // 是否隐藏头部红条
    hideCouponHeader: {
      type: Boolean,
      default: false
    },
    updateStyle: {
      type: Boolean,
      default: false
    },
    couponType: {
      type: String,
      default: ''
    },
    forRevisitComponent: {
      type: Boolean,
      default: false
    },
    showNewTransportationWay: {
      // 券包展示新的交通运输方式
      type: Boolean,
      default: false
    },
    isloaded: {
      type: Boolean,
      default: false
    },
    expandAbtInfo: {
      type: Object,
      default: () => {}
    },
    // 自定义颜色
    themeConfig: {
      type: Object,
      default: () => {}
    },
    showWraperImg: {
      type: Boolean,
      default: true
    },
    page: {
      // 哪个页面的券列表
      type: String,
      default: '',
    },
    showDateRange: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      PUBLIC_CDN,
      IMG_LINK,
      GB_cssRight,
      isTipIconShow: false,
      isTipIconShowClass: false,
      isCheckout: false,
      setExpand: true,
      isHeadOld: false,
      isMultMall: false,
      heights: [],
      hdHeight: 0
    }
  },
  computed: {
    isGreenThemeConfig(){
      return this.themeConfig?.['--themeColor'] == '#169E00'
    },
    headType() {
      if(this.isNewUser){
        if ((this.item?.coupon_rule?.length == 1)) {
          return 'head-old'
        }else {
          // 新用户券包多档头部显示row排列
          return 'head-row'
        }
      }else {
        // 会员券保持老头部样式
        if (this.item?.real_type_id == '53') {
          return 'head-old'
        }
        if (this.item?.coupon_rule?.length == 1 || this.isHeadOld) {
          return 'head-old'
        }
        return `head-${this.directionType}`
      }
    },
    isExpand() {
      return this.expanded === this.item.id
    },
    unusedCouponHas() {
      return (
        (this.unusedCouponType && this.unusedCouponShow) ||
        (!this.unusedCouponType && !this.unusedCouponShow)
      )
    },
    isShowNewlyGetFlag () {
      if (this.isShowNewly) {
        const { bind_time } = this.item
        if (!bind_time) return false
        const nowDate = new Date().getTime()
        if (nowDate < bind_time * 1000 + 12 * 60 * 60 * 1000) {
          return true
        }
      }
      return false
    },
    // 是否展示倒计时
    isShowCountdownFlag () {
      if (!this.showCoupondown) return false
      const { start_date, timeStatus, end_date } = this.item
      let [
        timeStep,
        newDate,
        endDate,
        startDate
      ] = [
        72 * 60 * 60 * 1000, //倒计时72小时
        new Date().getTime(), // 当前时间
        end_date * 1000, // 有效期结束时间
        start_date * 1000, // 有效期开始时间
      ]

      if (timeStatus === '1') {
        // 不在倒计时范围内 距离开始时间 72 小时内
        return startDate - newDate <= timeStep
      }

      // 不在倒计时范围内
      if (newDate + timeStep <= endDate) return false
      return true
    },
    // 是否有使用时间
    hasDateRange () {
      if (this.item?.start_date && this.item?.end_date) {
        return true
      }
      return false
    },
    getAOrB(){
      return this.forRevisitComponent || this.couponType == 'revisit-coupon'
    },
    isExpandCoupon() {
      const { CodeExpandType } = this.expandAbtInfo || {}
      if (CodeExpandType === 'off' || CodeExpandType === 'B') return false
      if(this.item.expandStatus === 'btn') return true
      if(this.item.expandStatus == 'seal' && this.isloaded) {
        return true
      }
      return false
    },
    couponBackgroundStyle() {
      const urlMap = {
        // 会员会费券
        '58': {
          default: `${PUBLIC_CDN}/she_dist/images/coupon/coupon_for_prime_bg-42a66cd895.png`,
          ar: `${PUBLIC_CDN}/she_dist/images/coupon/coupon_for_prime_bg_ar-954e86bb79.png`
        },
        // 超省卡会费券
        '59': {
          default: `${PUBLIC_CDN}/she_dist/images/coupon/coupon_for_saver_bg-db95f83547.png`,
          ar: `${PUBLIC_CDN}/she_dist/images/coupon/coupon_for_saver_bg_ar-2a42f283e3.png`
        }
      }
      const url = GB_cssRight ? urlMap[this.item.real_type_id]?.ar : urlMap[this.item.real_type_id]?.default
      if (url) return { backgroundImage: `url(${url})` }
      return {}
    },
    couponBackgroundLogo() {
      const urlMap = {
        // 会员会费券
        '58': {
          default: `${PUBLIC_CDN}/she_dist/images/coupon/coupon_for_prime_logo-f662d7291e.png`,
          ar: `${PUBLIC_CDN}/she_dist/images/coupon/coupon_for_prime_logo_ar-1806c5381c.png`
        },
        // 超省卡会费券
        '59': {
          default: `${PUBLIC_CDN}/she_dist/images/coupon/coupon_for_saver_logo-5fb6840436.png`,
          ar: `${PUBLIC_CDN}/she_dist/images/coupon/coupon_for_saver_logo_ar-bd3efeb3bb.png`
        }
      }
      const url = GB_cssRight ? urlMap[this.item.real_type_id]?.ar : urlMap[this.item.real_type_id]?.default
      if (url) return url
      return ''
    },
    isNewStyle() {
      return [
        PageSourceEnum.CHECKOUT,
        PageSourceEnum.CART,
        PageSourceEnum.USER_COUPON
      ].includes(this.page)
    }
  },
  watch: {
    updateStyle(val) {
      if (val) {
        this.heights = []
        this.$nextTick(() => {
          this.setMoreTipIconState()
          // 分割线高度
          this.setMidLine()
        })
      }
    },
    isloaded() {
      this.$nextTick(() => {
        this.setMidLine()
        this.setMoreTipIconState()
      })
    },
    showCouponCode: {
      handler() {
        this.$nextTick(() => {
          this.setMidLine()
        })
      },
      immediate: true
    },
    'item.coupon' (val, oldV) {
      if(val !== oldV) {
        this.heights = []
        this.$nextTick(() => {
          // 相同位置券内容不同，样式重新计算
          this.setMoreTipIconState()
        })
      }
    },
    isExpand (val) {
      if (!val) {
        this.noExpandUpdateStyle()
      }
    },
  },
  created() {
    this.getIsMultMall()
  },
  mounted() {
    this.$nextTick(() => {
      this.setMoreTipIconState()
      this.setExpand = false

      //下单页不展示凑单入口，个人中心优惠券列表页展示
      this.isCheckout = !!window.GB_orderPlaceVue
      setTimeout(() => {
        const headLeftEle = document.querySelector(`#coupon-item-${this.item.keyId} .head-left`)
        const headRightEle = document.querySelector(`#coupon-item-${this.item.keyId} .head-right`)
        if (headRightEle?.offsetHeight > headLeftEle?.offsetHeight) {
          headRightEle.style.marginTop = '8px'
        }
        this.setMidLine()
      }, 500)
      this.unobserveDomChange = this.initDomChangeObserver()
    })
  },
  beforeUnmount() {
    if (typeof this.unobserveDomChange === 'function') {
      this.unobserveDomChange()
    }
  },
  methods: {
    htmlDecode: htmlDecode,
    template: template,
    initDomChangeObserver() {
      try {
        const onDomChange = debounce({
          func: () => {
            this.$nextTick(() => this.setMidLine())
          },
          wait: 100,
        })
        const observer = new MutationObserver(onDomChange)
        const node = document.getElementById(`coupon-item-${this.item.keyId}`)
        observer.observe(node, { attributes: true, childList: true, subtree: true })
        return () => {
          observer.disconnect()
        }
      } catch(e) {
        // eslint-disable-next-line no-console
        console.log(e)
      }
    },
    exposeAnalyse (item) {
      const content_list = `${item.id}_${this.isShowCountdownFlag ? 'countdown' : 'interval'}`
      return {
        id: this.exposeId,
        data: {
          content_list: content_list,
          upgrade_type: !this.item.expandStatus ? '' : ['seal', 'subscript'].includes(this.item.expandStatus) ? 1 : 0,
          abtest: this.abtest,
          code: item.coupon
        }
      }
    },
    // 站点mall信息
    async getIsMultMall() {
      const { mallInfoForSite } = await getSiteMallInfo()
      this.isMultMall = mallInfoForSite?.length > 1
    },
    // 初始化分割线
    setMidLine () {
      const cycleRadius = 6
      const $coupon = document.getElementById(`coupon-item-${this.item.keyId}`)
      const $head = document.querySelector(`#coupon-item-${this.item.keyId} .head`)
      const couponWidth = window.getComputedStyle($head).width
      const couponHeight = window.getComputedStyle($coupon).height
      this.hdHeight = window.getComputedStyle($head).height
      // 计算卡券中心位置与缺角实际位置偏移
      const moveY = parseFloat(this.hdHeight) - (parseFloat(couponHeight) / 2  - cycleRadius)
      $coupon.style.webkitMask = `radial-gradient(circle, transparent ${cycleRadius}px, black ${cycleRadius}px) ${-parseFloat(couponWidth) / 2}px ${moveY + 1}px`
    },
    noExpandUpdateStyle () {
      this.$nextTick(() => {
        const tipsHeight = this.heights.reduce((a, b) => a + b, 0)
        const ele = document.getElementById(`coupon-desc-content-${this.item.keyId}`)
        if (ele) ele.style.maxHeight = `${tipsHeight + (this.heights.length - 1) * 4}px`
      })
    },
    // 展开收起文案
    handleClick() {
      if (this.isExpand) {
        this.noExpandUpdateStyle()
        this.$emit('triggerCollapse')
        daEventCenter.triggerNotice({ daId: '1-22-1-3' })
      } else {
        const ele = document.getElementById(`coupon-desc-content-${this.item.keyId}`)
        if (ele) ele.style.maxHeight = 'none'
        this.$emit('triggerExpand', this.item.id)
        daEventCenter.triggerNotice({ daId: '1-22-1-2' })
      }
      this.setMidLine()
    },
    setMoreTipIconState() {
      if (this.getAOrB) return

      const ids = [
        'data',
        'use',
        'card',
        'payment',
        'other',
        'post-amount',
        'shipping-methods',
        'mall-info',
        'unbind',
        'right-product',
        'right-cycle',
        'designated-shiping'
      ]
      // TR-25501 - 券码更换位置
      this.isNewStyle && ids.unshift('code')
      let rowNum = 0
      let flag = true
      let flag28 = false
      ids.forEach((id, index) => {
        const el = document.getElementById(`${id}-${this.item.coupon}`)
        if (!el) return
        rowNum = rowNum + getTextLline(el)
        if (!flag) return
        if (index == 1 && rowNum >= 3) {
          this.heights.push(parseInt(window.getComputedStyle(el).lineHeight, 10) * 2)
          flag = false
          flag28 = true
        } else if (index >= 2 && rowNum >= 3) {
          const sumHeight = this.heights.reduce((total, h) => total += h, 0)
          if(!flag28 && sumHeight <= 14) {
            this.heights.push(28)
            flag28 = true
          } else {
            this.heights.push(14)
          }
          flag = false
        } else if (rowNum <= 3 && el.offsetHeight) {
          this.heights.push(el.offsetHeight)
        }
      })
      const tipsHeight = this.heights.reduce((a, b) => a + b, 0)
      const liMarginTop = 4
      const ele = document.getElementById(`coupon-desc-content-${this.item.keyId}`)
      if (ele) ele.style.maxHeight = `${tipsHeight + (this.heights.length - 1) * liMarginTop}px`
      this.isTipIconShow = rowNum > 3
    },
    viewCouponList () {
      this.$emit('view-coupon-list')
    }
  },
  emits: ['triggerExpand', 'triggerCollapse', 'view-coupon-list'],
}
</script>
<style lang="less" scoped>
.check-btn{
  position: absolute;
  color: #2D68A8;
  top: 0;
  right: 15px;
  cursor: pointer;
}
.body {
  &.expand {
    .icon-tip {
      &__icon {
        transform: rotate(0deg);
      }
    }
  }
  .icon-tip {
    position: absolute;
    right: 8px;
    bottom: 10px;
    color: #999;
    padding: 0;
    display: inline-block;
    &__icon {
      transform: rotate(180deg);
    }
  }
}
</style>

