<template>
  <section class="title-section">
    <div class="coupon-title-warp">
      <div
        class="coupon-title__text"
        :style="{ '--text-indent': !showWraperImg ? 0 : '' }"
      >
        <!-- 超省卡券标识 -->
        <i
          v-if="item.real_type_id == '55' || item.real_type_id == '59'"
          class="icon_saver"
          :style="{
            background: `url(${PUBLIC_CDN}/she_dist/images/prime/saver/${isExpired ? 'sui_icon_saver_disable-39f4cc3b3d' : 'icon_saver-a30c7d2571'}.png)`,backgroundSize: 'cover'
          }"
        ></i>
        <!-- 付费会员标识 -->
        <Icon
          v-if="(showWraperImg && item.real_type_id == '53') || item.real_type_id == '58'"
          class="wraper-img"
          name="sui_icon_club_logo_fill_18px"
          color="#873C00"
          size="18px"
        />
        
        <span 
          v-if="item.apply_for == 101"
          :class="{
            'coupon-title__text_indent': [53, 55, 58, 59].includes(+item.real_type_id),
          }"
        >
          {{ item.coupon_rule[0].value.amountWithSymbol }} {{ language.SHEIN_KEY_PC_16347 || "OFF" }}
        </span>
        <template v-else>
          <span
            v-if="(item.real_type_id == '53' || item.real_type_id == '55' || item.apply_for == '9') && item.shipping_discount_type == 0"
            class="title"
            :class="{
              'coupon-title__text_indent': [53, 55, 58, 59].includes(+item.real_type_id),
            }"
          >
            {{ language.SHEIN_KEY_PC_15911 }}
          </span>
          <!-- 实际优惠信息 -->
          <span
            v-else
            :id="`title-${item.coupon}-${index}`"
            class="title"
            :class="{ 
              'add-title-effects':isTitleEffectsCode == item.coupon,
              'coupon-title__text_indent': [53, 55, 58, 59].includes(+item.real_type_id),
            }"
          >
            <template v-if="['5', '6'].includes(item.apply_for)">
              {{ language.SHEIN_KEY_PC_15911 }}
            </template>
            <template v-else-if="item.coupon_type_id == '1'">
              {{
                directionType == "row"
                  ? `-${couponRule.value.amountWithSymbol}`
                  : template(
                    couponRule.value.amountWithSymbol,
                    getTitleLanguage(couponRule)
                  )
              }}
            </template>
            <template v-else>
              {{
                template(
                  percentFormat(couponRule.value.amount),
                  getTitleLanguage(couponRule)
                )
              }}
            </template>
          </span>
        </template>
      </div>

      <span
        v-if="(item.real_type_id == '53' || item.real_type_id == '55' || item.apply_for == '9') && item.shipping_discount_type == 1"
        class="shiping-coupon-tip"
        :class="{
          'is-green': isGreenTheme
        }"
      >
        {{ isGreenTheme ? language.SHEIN_KEY_PC_29184 : language.SHEIN_KEY_PC_23637 }}
      </span>
      <!-- 下单页可叠加标识（单档券） -->
      <span
        v-if="item.coupon_rule.length <= 1 && stageable"
        class="stackable-coupon-tip"
      >
        {{ language.SHEIN_KEY_PC_22135 }}
      </span>
    </div>

    <!-- 优惠券门槛信息 -->
    <p
      class="coupon-sub-title"
      :style="getStyle"
    >
      {{ couponSubTitle }}
    </p>
  </section>
</template>

<script>
const { IMG_LINK, GB_cssRight, PUBLIC_CDN, IS_RW } = gbCommonInfo
import { htmlDecode as commonHtmlDecode, template } from '@shein/common-function'
import { getTextLline } from '../utils'
import { Icon } from '@shein-aidc/icon-vue3'
export default {
  name: 'CouponSubTitle',
  components: {
    Icon,
  },
  props: {
    ruleLen: {
      type: Number,
      default: 0
    },
    ssr: {
      type: Boolean,
      default: false,
    },
    isNewUser: {
      type: Boolean,
      default: false
    },
    isExpired: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: null,
    },
    couponRule: {
      type: Object,
      default: () => { },
    },
    language: {
      type: Object,
      default: null,
    },
    titleLength: {
      type: Number,
      default: 0,
    },
    index: {
      type: Number,
      default: 0,
    },
    isSatisfiedRange: {
      type: Boolean,
      default: false,
    },
    isShowFreeGift: {
      type: Boolean,
      default: true,
    },
    directionType: {
      // 多档券排列顺序
      type: String,
      default: 'column',
    },
    isNumberFlicker: {
      // 领取膨胀折扣卷数据闪动
      type: Boolean,
      default: true,
    },
    isOrderReturn: {
      type: Boolean,
      default: false
    },
    isTitleEffectsCode: {
      type: String,
      default: ''
    },
    couponType: {
      // 弹窗类型，用户回归：revisit-coupon
      type: String,
      default: ''
    },
    abtEstimated: { // abt控制参数, 是否命中到手价实验.如果命中，SHEIN_KEY_PC_15958 要换成 SHEIN_KEY_PC_26542。
      type: Boolean,
      default: false
    },
    mustShowMin: {
      type: Boolean,
      default: true
    },
    stageable: {
      // 是否可叠加
      type: Boolean,
      default: false,
    },
    isNewStyle: {
      // 是否新样式
      type: Boolean,
      default: false,
    },
    isGreenTheme: { // 是否命中商详绿色主题免邮券
      type: Boolean,
      default: false
    },
    showWraperImg: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      IMG_LINK,
      IS_RW,
      GB_cssRight,
      PUBLIC_CDN
    }
  },
  computed: {
    couponSubTitle() {
      const min = this.couponRule?.min?.amountWithSymbol || ''
      const max = this.couponRule?.max?.amountWithSymbol || ''
      // 付费会员会费券
      if (this.item.real_type_id == '58') return this.template(
        min,
        this.language.SHEIN_KEY_PC_28846
      )
      // 超省卡会费券
      if (this.item.real_type_id == '59') return this.template(
        min,
        this.language.SHEIN_KEY_PC_28834
      )
      if(this.isNewUser && this.item.coupon_rule.length > 1){
        return this.template(
          min,
          this.htmlDecode({ text: this.language['SHEIN_KEY_PC_26117'] + '+' })
        )
      }else {
        if (max) {
          return this.template(
            min,
            max,
            this.htmlDecode({
              text: this.language.SHEIN_KEY_PC_19368,
            })
          )
        }
        const mustShowMin = !this.isNewStyle && this.mustShowMin
        if(this.couponRule?.min?.amount > 0 || mustShowMin){
          // 处理支付成功页多档券，SHEIN_KEY_PWA_24869
          const currentPage = gbCommonInfo?.templateType === 'paySuccess'
          const minKey = this.isNewStyle ? 'SHEIN_KEY_PC_28966' : 'SHEIN_KEY_PC_15958'
          return this.template(
            min,
            this.htmlDecode({ text: this.language[currentPage ? 'SHEIN_KEY_PC_26121' : this.isOrderReturn ? 'SHEIN_KEY_PC_25655' : (this.abtEstimated ? 'SHEIN_KEY_PC_26542' : minKey)] })
          )
        } else if (Number(this.couponRule?.min?.amount) === 0 && this.item.apply_for !== 101) {
          // 无门槛券，且非ab价 展示文案
          const noThresholdKey = this.isNewStyle ? 'SHEIN_KEY_PC_28967' : 'SHEIN_KEY_PC_27865'
          return this.language[noThresholdKey] || ''
        } else {
          return ''
        }
      }
    },
    getStyle() {
      if(this.isNewUser) {
        if(GB_cssRight){
          return {
            fontSize: '12px',
            lineHeight: '20px',
            textAlign: 'right'
          }
        }else {
          return {
            fontSize: '12px',
            lineHeight: '20px',
            textAlign: 'left'
          }
        }
      }
      if (this.couponType == 'revisit-coupon') {
        return {
          fontSize: '12px',
          lineHeight: '20px'
        }
      } else {
        return {}
      }

    }
  },
  mounted() {
    const el = document.getElementById(`title-${this.item.coupon}-${this.index}`)
    if (el) {
      const textLine = getTextLline(el)
      textLine > 1 && this.$emit('update:titleLength', textLine)
    }
    this.textOver7()
  },

  methods: {
    template,
    htmlDecode (args) {
      return commonHtmlDecode({ ...args, isHtml: this.ssr })
    },
    textOver7(){
      const el = document.getElementById(`title-${this.item.coupon}-${this.index}`)
      if(el?.innerText?.length > 9 && this.ruleLen >= 2) {
        el.style.fontSize = '12px'
        el.style.whiteSpace = 'normal'
      }
    },
    // 获取标题文案
    getTitleLanguage(couponRule) {
      if (this.item.real_type_id == '53') return '{0}'
      if (this.item.real_type_id == '58') return this.language.SHEIN_KEY_PC_28845
      if (this.item.real_type_id == '59') return this.language.SHEIN_KEY_PC_28833
      if (!this.isShowFreeGift)
        return this.htmlDecode({ text: this.language.SHEIN_KEY_PC_15957 })
      if (this.isSatisfiedRange) {
        return this.htmlDecode({
          text:
            this.index <= this.item.satisfied_range - 1 &&
              couponRule.coupon_gift_id
              ? this.language.SHEIN_KEY_PC_18189
              : this.language.SHEIN_KEY_PC_15957,
        })
      }
      return this.htmlDecode({
        text: couponRule.coupon_gift_id
          ? this.language.SHEIN_KEY_PC_18189
          : this.language.SHEIN_KEY_PC_15957,
      })
    },
    // 百分比格式化
    percentFormat(num) {
      const newNum = (num * 100) / 100
      const percentNum = GB_cssRight ? `%${newNum}` : `${newNum}%`
      return percentNum
    },
  },
  emits: ['update:titleLength'],
}
</script>

<style lang="less">
.title-section {
  width: 100%;
}
.icon_saver {
  width: 20px;
  height: 20px;
}
.wraper-img {
  display: inline-block;
}

.coupon-title {
  &__text {
    display: flex;
    position: relative;

    .icon_saver,
    .wraper-img {
      position: absolute;
      top: 0;
      left: 0;
    }

    &_indent {
      text-indent: var(--text-indent, 22px);
    }
  }
}


.shiping-coupon-tip {
  font-size: 10px;
  height: 16px;
  line-height: 16px;
  padding: 0 6px;
  background: rgba(255, 186, 151, 0.6);
  font-weight: 400;
  margin-left: 4px;
  &.is-green{
    background-color: rgba(22, 158, 0, 0.10);
  }
}
.stackable-coupon-tip {
  font-family: SF UI Display;
  font-size: 10px;
  padding: 0 4px;
  border-radius: 1px;
  border: 1px solid rgba(51, 126, 255, 0.2);
  line-height: 16px;
  font-weight: 400;
  margin-left: 4px;
  white-space: nowrap;
  background-color: @sui_color_note_light_bg;
  color: @sui_color_link;
}

.number-flicker {
  animation: flicker 0.5s;
}
@keyframes flicker {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 10px);
  }
  100% {
    transform: translate(0, 0px);
  }
}
.add-title-effects {
  animation: textEffects 0.6s;
}
@keyframes textEffects {
  0% {
    opacity: 0;
    transform: translate(0, -10px);
  }
  // 50% {
  //   opacity: 1;
  //   transform: translate(0, -10px);
  // }
  100% {
    opacity: 1;
    transform: translate(0, 0px);
  }
}
</style>
