<template>
  <section class="coupon-main">
    <!-- 指定运输方式减运费券可叠加提示语 -->
    <p v-if="showStackableTips && item.apply_for == 9">
      {{ language.SHEIN_KEY_PC_29099 }}
    </p>
    <!-- （商详页范围内）减运费券可叠加提示语 -->
    <p v-if="isForDetail && isStackable">
      {{ language.SHEIN_KEY_PC_29186 }}
    </p>
    <!-- 优惠券券码 -->
    <p
      v-if="!isNewStyle && showCouponCode && item.coupon"
      class="coupon-item-content coupon-main_tip"  
    >
      <span v-if="incouponpkg">{{
        `${language.SHEIN_KEY_PC_16847}: ${ (expanded ? afterCode : item.coupon || "").toUpperCase()}`
      }}</span>
      <span v-else>{{
        `${language.SHEIN_KEY_PC_16847}: ${ (item.coupon || "").toUpperCase()}`
      }}</span>
      <span
        v-if="item.is_bind === 0"
        v-expose="getCopyAnalysis('1-6-1-161')"
        v-tap="getCopyAnalysis('1-6-1-162')"
        :data-clipboard-text="(item.coupon || '').toUpperCase()"
        class="copy-code-btn" 
        @click="copyCode"
      >
        {{ language.SHEIN_KEY_PC_26393 }}
      </span>
    </p>
    <div
      v-if="tagList.length"
      class="coupon-tag-list"
    >
      <span
        v-for="(tag, index) in tagList" 
        :key="tag + '-' + index"
        class="coupon-tag-item"
      >
        {{ tag }}
      </span>
    </div>
   

    <!-- 优惠券实际优惠信息 -->
    <p
      v-if="showCouponRealDiscount && realDiscountPriceText"
      class="coupon-main_tip"
    >
      <span v-html="realDiscountPriceText"></span>
      <Icon
        v-if="showGoodsIcon"
        class="goods-icon-tips"
        name="sui_icon_doubt_14px_1"
        color="#959595"
        size="14px"
        @click.stop="isShowGoodsHandle"
      />
    </p>

    <!-- 最大抵扣金额 -->
    <p
      v-if="showCouponMaxDiscount && maxDiscountPriceText"
      class="coupon-main_tip"
      v-html="maxDiscountPriceText"
    ></p>

    <!-- 下单页可叠加标识已移至 couponSubTitle 中 -->
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import { template } from '@shein/common-function'
import Clipboard from 'clipboard'
import { couponTagHandleMap } from './utils'
import { Icon } from '@shein-aidc/icon-vue3'

export default {
  name: 'CouponInfo',
  components: {
    Icon
  },
  props: {
    incouponpkg: {
      type: Boolean,
      default: false
    },
    expanded: {
      type: String,
      default: ''
    },
    item: {
      type: Object,
      default: null,
    },
    language: {
      type: Object,
      default: null,
    },
    showCouponCode: { // 是否展示优惠券券码
      type: Boolean,
      default: true,
    },
    showCouponRealDiscount: {
      // 是否展示实际抵扣金额
      type: Boolean,
      default: false,
    },
    showCouponMaxDiscount: {
      // 是否展示最大抵扣金额
      type: Boolean,
      default: false,
    },
    stageable: {
      // 是否可叠加
      type: Boolean,
      default: false,
    },
    showGoodsIcon: { // 是否展示可用商品提示icon
      type: Boolean,
      default: false
    },
    // 商详用 - 是否展示New User Gitfs标识 abt
    abtNewUserGifts: {
      type: Boolean,
      default: false,
    },
    isNewStyle: {
      // 是否新样式
      type: Boolean,
      default: false,
    },
    // 是否展示可叠加提示语
    showStackableTips: {
      type: Boolean,
      default: false,
    },
    //（商详页范围内）减运费券可叠加提示语
    isForDetail: {
      type: Boolean,
      default: false,
    }
  },
  data(){
    return {
      afterCode: ''
    }
  },
  computed: {
    ...mapGetters([
      'screenAbtConfig'
    ]),
    // 实际抵扣金额
    realDiscountPriceText() {
      let result = ''
      const {
        real_discount_price = {},
        limit_price = {},
        need_price = {},
      } = this.item
      if (!real_discount_price || !need_price) return result

      if (+need_price?.amount > 0) {
        // 优惠券未满足门槛
        result = this.template(
          `<em class="discount-price-unreached">${need_price.amountWithSymbol}</em>`,
          this.language.SHEIN_KEY_PC_17379
        )
      } else {
        // 优惠券已满足门槛
        if (+real_discount_price?.amount > 0) {
          const tpl = `<em class="discount-price">${real_discount_price.amountWithSymbol}</em>`
          if (
            !limit_price?.amount ||
            +real_discount_price?.amount < +limit_price?.amount
          ) {
            // 未达到最大抵扣金额
            result = this.template(tpl, this.language.SHEIN_KEY_PC_17378)
          } else {
            // 达到最大抵扣金额
            result = this.template(tpl, this.language.SHEIN_KEY_PC_22598)
          }
        }
      }
      return result
    },
    // 最大抵扣金额
    maxDiscountPriceText() {
      let result = ''
      if (
        this.item?.limit_price?.amount &&
        +this.item?.limit_price?.amount > 0
      ) {
        result = this.template(
          this.item?.limit_price?.amountWithSymbol,
          this.language.SHEIN_KEY_PC_22599
        )
      }
      return result
    },
    tagList({ language }) {
      return Object.values(couponTagHandleMap).sort(
        (a, b) => a.sort - b.sort
      ).filter(
        ({ check, langKey }) => check?.(this) && language?.[langKey]
      ).map(({ langKey }) => language[langKey])
    },
    // 判断免邮券是否可叠加
    isStackable(){
      return this.item.apply_for == 9 && [53, 55, 61].includes(+this.item.real_type_id) &&  this.screenAbtConfig?.shippingcouponstack?.param?.shippingcouponstackswitch === 'on'
    }
  },
  watch: {
    showGoodsIcon (val) {
      if (val) this.$emit('goods-icon-change')
    }
  },
  mounted(){
    let beforeExpandCouponCode = window.localStorage.getItem('beforeExpandCouponCode')
    let realBind = JSON.parse(window.localStorage.getItem('realBind'))
    // 如果是点了膨胀，显示膨胀后的券码
    if(beforeExpandCouponCode && realBind) {
      let storageAfterCode = window.localStorage.getItem('afterCodes') // JSON.stringify(Array.from(afterCodes.entries()))
      this.afterCode = new Map(JSON.parse(storageAfterCode)).get(this.item.coupon) || this.item.coupon
    }else {
      this.afterCode = this.item.coupon
    }
  },
  methods: {
    template,
    isShowGoodsHandle () {
      this.$emit('is-show-goods-handle')
    },
    copyCode(){
      const clipboard = new Clipboard(`.copy-code-btn`)
      clipboard.on('success', () => {
        const tip = this.template(
          this.item.coupon || '',
          this.language.SHEIN_KEY_PC_26199
        )
        this.$toast({
          htmlString: tip
        }, 3000)
        clipboard.destroy()
      })
    },
    getCopyAnalysis(id){
      return {
        id,
        data: {
          location: 0,
          id: this.item.coupon
        }
      }
    }
  },
  emits: ['goods-icon-change', 'is-show-goods-handle']
}
</script>

<style lang="less">
.coupon-main {
  width: 100%;
  .copy-code-btn{
    cursor: pointer;
    color: #2D68A8;
    padding-left: 8px;
    flex: 1;
    text-align: left;
  }
  .coupon-tag-list{
    margin-top: 8px;
    font-size: 12px;
    line-height: 16px;
    font-weight: normal;
    word-break: break-all;
    .coupon-tag-item{
      font-size: 10px;
      padding: 2px 4px;
      background: var(--themeColor, @sui_color_welfare);
      color: #FFFFFF;
      margin-right: 4px;
      display: inline-block;
    }
  }
}
.goods-icon-tips {
  color: @sui_color_gray_light1;
  .margin-l(.106667rem);
}

.sui-toast__content {
  word-wrap: break-word;
}
</style>
