<template>
  <div
    v-if="categories.length"
    ref="couponCategoriesRef" 
    class="coupon-categories"
    :class="{
      'coupon-categories_collapsed': collapsed,
    }"
  >
    <span 
      v-for="(category, catIndex) in categories.slice(0, lastDisplayedIndex + 1)"
      :key="catIndex"
      class="coupon-categories__item"
    >
      {{ category }}
    </span>
    <span
      v-if="!collapsed || (collapsed && lastDisplayedIndex < categories.length - 1)"
      class="coupon-categories__item coupon-categories__icon"
    >
      <span  
        class="coupon-categories__icon_click"
        @click.stop="handleCollapseToggle()"
      ></span>
      <Icon
        v-if="collapsed"
        name="sui_icon_more_down_12px_1" 
        size="10px" 
      />
      <Icon
        v-else
        name="sui_icon_more_up_12px_1" 
        size="10px" 
      />
    </span>
  </div>
</template>
<script>
import { debounce } from 'lodash'
import { template } from '@shein/common-function'
import { Icon } from '@shein-aidc/icon-vue3'

export default {
  components: { Icon },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      categories: [],
      lastHeight: null,
      windowWidth: window.innerWidth,
      resizeObserver: null,
      isResizeHandling: false,
      collapsed: true,
      // 手动折叠展开
      toggle: false,
      // 折叠后最后一个显示的标签 index（不包括折叠标签）
      lastDisplayedIndex: 999
    }
  },
  mounted() {
    this.bindEventListener()
    this.initCategories()
    this.$nextTick(() => {
      this.bindResizeObserver()
    })
  },
  methods: {
    template,
    bindEventListener() {
      this.handleWindowResize = debounce(() => {
        const newWidth = window.innerWidth
        if (newWidth > this.windowWidth) {
          // 浏览器窗口放大时，标签位置有变化，但没有触发容器尺寸变化，需要手动处理一下
          this.lastDisplayedIndex = this.categories.length - 1
          this.handleCollapsedResize()
        }
        this.windowWidth = newWidth
      }, 200)
      window.addEventListener('resize', this.handleWindowResize)
    },
    bindResizeObserver() {
      const resizeObserver = new ResizeObserver((entries) => {
        for (let entry of entries) {
          const currentHeight = entry.contentRect.height
          // 手动折叠展开引发的尺寸变更
          if (this.toggle) {
            this.toggle = false
            // 更新尺寸
            this.lastHeight = currentHeight
            return
          }
          // 折叠时，位置不够导致的换行
          if (currentHeight > this.lastHeight) this.handleCollapsedResize()
          // 更新尺寸
          this.lastHeight = currentHeight
        }
      })
      resizeObserver.observe(this.$refs.couponCategoriesRef)
    },
    initCategories() {
      const categories = []
      this.item.category_name.forEach(item => {
        if (item.children.length > 0) {
          item.children.forEach(child => {
            categories.push(`${item.cat_name_multi}-${child.cat_name_multi}`)
          })
        } else {
          categories.push(item.cat_name_multi)
        }
      })
      this.categories = [...categories]
      this.lastDisplayedIndex = categories.length - 1
    },
    handleCollapsedResize(rehandle) {
      const { couponCategoriesRef } = this.$refs
      if (!couponCategoriesRef || !couponCategoriesRef.children.length) return
      // 如果非折叠状态，不需要处理
      if (this.isResizeHandling) return
      this.isResizeHandling = true
      const rowHeight = couponCategoriesRef.children[0].clientHeight
      const marginTop = couponCategoriesRef.children[0].offsetTop
      if (couponCategoriesRef.offsetHeight > rowHeight + marginTop) {
        // 包含折叠标签
        const displayedChildren = [...couponCategoriesRef.children].slice(0, this.lastDisplayedIndex + 2)
        const index = displayedChildren.findIndex(child => child.offsetTop > marginTop)
        if (index === 0) {
          // 第一个标签长于一行时
          this.lastDisplayedIndex = index
        } else if (index >= displayedChildren.length - 1 && !this.collapsed) {
          // 元素都没有换行，且展开状态，即折叠标签换行了
          this.collapsed = true
          this.lastDisplayedIndex = this.categories.length - 1
        } else if (index > 0 && this.collapsed) {
          this.lastDisplayedIndex = rehandle ? this.lastDisplayedIndex - 1 : index - 1
          this.isResizeHandling = false
          // 折叠标签因不够位置而换行时需要重新计算，处理一次就够了
          if(!rehandle) this.$nextTick(() => { this.handleCollapsedResize(true) })
        }
        this.isResizeHandling = false
      }
      this.isResizeHandling = false
    },
    handleCollapseToggle() {
      this.toggle = true
      this.collapsed = !this.collapsed
      if (this.collapsed) {
        // 折叠时需要重新计算折叠标签的位置，因为浏览器窗口尺寸可能有所变化
        this.handleCollapsedResize()
      } else {
        // 展开
        this.lastDisplayedIndex = this.categories.length - 1
      }
    }
  }
}
</script>
<style lang="less" scoped>
.coupon-categories {  
  position: relative; 
  display: flex;
  margin-left: -4px;
  flex-wrap: wrap;
  width: 100%;
  &__item {
    max-width: calc(100% - 25px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-radius: 1px;
    background: fade(@sui_color_welfare, 10%);
    padding: 0 4px;
    margin: 6px 0 0 4px;
    color: @sui_color_welfare_dark;
    font-size: 10px;
    font-weight: 400;
    white-space: nowrap;
    height: 15px;
    line-height: 15px;
  }

  

  &__icon {
    text-align: center;
    padding: 0;
    width: 15px;
    height: 15px;
    position: relative;

    &_click {
      cursor: pointer;
      text-align: center;
      width: 20px;
      height: 20px;
      position: absolute;
      top: -2.5px;
      left: -2.5px;
    }
  }
}
</style>
